<template>
  <Modal @close="$emit('close', downloadPage)">
    <h4 class="mb-2">{{ captions.title }}</h4>

    <form @submit.prevent="editUser">
      <UserDataForm @update:modelValue="mergeUserData" :modelValue="user" :noPassword="!create" />

      <div class="row">
        <SelectField required v-model="user.role" :options="roleOptions" label="Rolle" icon="badge" class="col m6 s12" />
        <SelectField required v-model="user.companyId" :options="companyOptions" label="Unternehmen" icon="business" class="col m6 s12" />
      </div>

      <div class="section">
        <button :disabled="busy" type="submit" class="waves-effect waves-light btn">
          {{ captions.button }}
        </button>
      </div>
    </form>
	</Modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import SelectField from "@/components/SelectField.vue";
import UserDataForm from "@/components/UserDataForm.vue";
import { actions, events } from "@/constants.js";

export default {
  name: "ModalEditUser",
  emits: [events.CLOSE, events.UPDATE_MODEL_VALUE],
  data() {
    return {
      user: this.copyUserData()
    };
  },
  props: {
    modelValue: {
      type: Object,
      required: true
    },
    create: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    captions() {
      if (this.create) return {
        title: "Nutzerkonto erstellen",
        button: "Account anlegen"
      };

      return {
        title: "Nutzerkonto verwalten",
        button: "Änderungen speichern"
      };
    },
    companyOptions() {
      return this.$store.getters.companyOptions;
    },
    roleOptions() {
      const options = [
        { name: "Nutzer", value: "user" },
        { name: "Manager", value: "manager" }
      ];

      if (this.isAdmin) options.push({ name: "Administrator", value: "admin" });

      return options;
    }
  },
  methods: {
    copyUserData() {
      const user = Object.assign({}, this.modelValue);
      if (!user.role) user.role = "user";
      if (!user.companyId || user.companyId < 0) user.companyId = 0;

      return user;
    },
    mergeUserData(userData) {
      this.user = Object.assign(this.user, userData);
    },
    async editUser() {
      const result = await this.$store.dispatch(actions.EDIT_USER, this.user);
      if (result === null) return;

      this.$emit(events.UPDATE_MODEL_VALUE, this.user);
      this.$emit(events.CLOSE);
    }
  },
  components: {
    Modal,
    SelectField,
    UserDataForm
  }
};
</script>

<style module lang="scss">
  .icon {
    margin-left: 10px;
  }
</style>
