<template>
  <div class="input-field">
    <i v-if="icon" class="material-icons prefix">{{ icon }}</i>
    <select :required="required" v-model="value" ref="select">
      <option v-for="option of options" :key="option.value" :value="option.value">{{ option.name }}</option>
    </select>
    <label v-if="label">{{ label }}</label>
  </div>
</template>

<script>
import M from "materialize-css";
import { events } from "@/constants.js";

export default {
  name: "SelectField",
  emits: [events.UPDATE_MODEL_VALUE],
  data() {
    return {
      instance: null
    };
  },
  props: {
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: false
    },
    icon: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit(events.UPDATE_MODEL_VALUE, value);
      }
    }
  },
  methods: {
    init() {
      if (this.instance) this.instance.destroy();
      this.instance = M.FormSelect.init(this.$refs.select);
    }
  },
  watch: {
    options() {
      this.$nextTick(this.init);
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style module lang="scss">

</style>
