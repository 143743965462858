<template>
  <button @click="createUser = true" :disabled="busy" class="btn ml-2">Account erstellen</button>
  <button v-if="companyId > 0" @click="createInvitation" :disabled="busy" class="btn ml-1">Einladungslink erstellen</button>

  <ModalEditUser create v-if="createUser" @update:modelValue="onCreateUser($event)" @close="createUser = false" :modelValue="{ companyId }" />
</template>

<script>
import { actions, events } from "@/constants.js";
import ModalEditUser from "@/components/ModalEditUser.vue";

export default {
  name: "UserManagementButtons",
  emits: [events.CREATE_INVITATION, events.CREATE_USER],
  data() {
    return {
      createUser: false
    };
  },
  props: {
    companyId: {
      required: true
    }
  },
  computed: {
    busy() {
      return this.$store.state.busy;
    }
  },
  methods: {
    onCreateUser(user) {
      this.$emit(events.CREATE_USER, user);
    },
    async createInvitation() {
      const result = await this.$store.dispatch(actions.CREATE_INVITATION, this.companyId);
      this.$toast.success("Einladungslink erstellt");
      this.$emit(events.CREATE_INVITATION, result);
    }
  },
  components: {
    ModalEditUser
  }
};
</script>

<style module lang="scss">

</style>
