<template>
  <div v-if="invitations.length">
    <h5 class="mt-4">Einladungen</h5>

    <DefaultTable :columns="cols" :rows="rows" :initialSortBy="{ field: 'createdAt', type: 'desc' }">
      <template #actions="data" style="width: 90px">
        <div class="flex-row flex-hcenter flex-grow">
          <CopyInputField :content="data.item.url" tooltip="Einladungslink kopieren" />
          <IconButton @clicked="removeInvitation(data.item.token)" icon="delete" iconClass="grey-text hover-red" tooltipOn="Einladungslink löschen" class="ml-05 mr-1" />
        </div>
      </template>
    </DefaultTable>
  </div>
</template>

<script>
import { actions, events, http, routes } from "@/constants.js";
import CopyInputField from "@/components/CopyInputField.vue";
import IconButton from "@/components/IconButton.vue";
import DefaultTable from "@/components/DefaultTable.vue";

export default {
  name: "InvitationList",
  emits: [events.DELETE],
  data() {
    return {
      modalUser: null
    };
  },
  props: {
    invitations: {
      type: Object,
      required: false
    },
    companyId: {
      required: true
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    cols() {
      return [
        { label: "Erstellt am", field: "createdAt", type: "defaultDate", firstSortType: "desc", thClass: this.$style.created, tdClass: this.$style.created },
        { label: "Link", field: "actions", type: "actions", sortable: false }
      ];
    },
    rows() {
      return this.invitations;
    }
  },
  methods: {
    async removeInvitation(invitation) {
      const result = await this.$store.dispatch(actions.API_REQUEST, {
        route: routes.COMPANY.invitationLinkDelete(this.companyId, invitation),
        method: http.DELETE
      });

      if (result === null) return;

      this.$toast.success("Einladungslink gelöscht");
      this.$emit(events.DELETE, invitation);
    }
  },
  components: {
    CopyInputField,
    IconButton,
    DefaultTable
  }
};
</script>

<style module lang="scss">
  .created {
    max-width: 4rem;
  }
</style>
