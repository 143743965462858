<template>
  <Spinner v-if="busy" />
  <div v-else>
    <DefaultTable searchable v-if="users.length" :columns="cols" :rows="mappedUsers" :initialSortBy="sortingProperties">
      <template #actions="data">
        <span v-if="data.item.editable">
<!--          <IconButton icon="delete" iconClass="grey-text hover-red" tooltipOn="Benutzerkonto löschen" class="mr-1" />-->
          <IconButton @clicked="modalUser = data.item" icon="edit" tooltipOn="Benutzerkonto verwalten" class="mr-1" />
        </span>
      </template>
    </DefaultTable>
    <h6 v-else class="mt-2">Keine Nutzer vorhanden</h6>
  </div>

  <ModalEditUser v-if="modalUser" @update:modelValue="$emit('edit', $event)" @close="modalUser = null" :modelValue="modalUser" />
</template>

<script>
import { events } from "@/constants.js";
import IconButton from "@/components/IconButton.vue";
import Spinner from "@/components/Spinner.vue";
import DefaultTable from "@/components/DefaultTable.vue";
import ModalEditUser from "@/components/ModalEditUser.vue";

export default {
  name: "UserList",
  emits: [events.EDIT],
  data() {
    return {
      modalUser: null
    };
  },
  props: {
    users: {
      type: Object,
      required: false
    },
    noCompany: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    busy() {
      return this.users === null || this.$store.state.busy;
    },
    isAdmin() {
      return this.$store.getters.isAdmin;
    },
    mappedUsers() {
      const result = [...this.users];
      result.forEach(this.preprocessUser);
      return result;
    },
    companyLookup() {
      return this.$store.getters.companyLookup;
    },
    cols() {
      return [
        { label: "Name", field: (u) => `${u.firstName} ${u.lastName}` },
        { label: "E-Mail", field: "email" },
        { label: "Telefon", field: "phone" },
        { label: "Unternehmen", field: "company", hidden: this.noCompany },
        { label: "Rolle", field: "role" },
        { label: "", field: "actions", type: "actions", sortable: false }
      ];
    },
    sortingProperties() {
      return [
        { field: "company", type: "asc" },
        { field: "role", type: "asc" }
      ];
    }
  },
  methods: {
    canEdit(user) {
      return this.isAdmin || user.role !== "admin";
    },
    preprocessUser(user) {
      user.company = this.companyLookup[user.companyId] || "";
      user.editable = this.isAdmin || user.role !== "admin";
      if (!user.companyId) user.companyId = -1;
    }
  },
  components: {
    IconButton,
    Spinner,
    DefaultTable,
    ModalEditUser
  }
};
</script>

<style module lang="scss">

</style>
